<template>
  <section id="main-wrapper" style="min-height: 95vh">
    <div class="container">
      <section class="page-title">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="breadcrumbs-wrap">
                <h1 class="breadcrumb-title">Bank Transfer</h1>
                <nav aria-label="breadcrumb">
                  <ol
                    class="breadcrumb"
                    style="display: inline-flex !important"
                  >
                    <li class="breadcrumb-item">
                      <b-link to="/checkout">Checkout</b-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Bank Transfer
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-0">
        <div class="container">
          <div class="row">
            <div class="col-lg-10 col-md-12 col-sm-12">
              <div>
                <h3>Need to pay using bank transfer?</h3>
                <h6>Please follow these instructions:</h6>
              </div>
              <div style="margin-top: 20px">
                <h5>Step 1</h5>
                <p>
                  <strong>Connect with us on whatsapp for assistance.</strong>
                </p>
                <a
                  class="btn btn-success"
                  :href="m_isMobile() ? m_whatsappUrl() : '#'"
                  @click="!m_isMobile() ? m_number() : ''"
                  :target="m_isMobile() ? '_blank' : ''"
                  >Get Assistance</a
                >
              </div>
              <div style="margin-top: 20px">
                <h5>Step 2</h5>
                <p>
                  <strong>
                    Check and confirm the total amount to pay and the subjects
                    you are trying to purchase.</strong
                  >
                </p>
                <b-btn
                  :class="['btn', 'btn-warning']"
                  @click="m_confirmsubjets()"
                  >Submit
                </b-btn>
              </div>
              <div style="margin-top: 20px">
                <h5>Step 3</h5>
                <p>
                  <strong>
                    Please await confirmation from our assistant. <br />Once
                    confirmed please go to your
                    <b-link
                      class="dashboard"
                      to="/sessions"
                      style="color: #0000ee"
                      >profile</b-link
                    >
                    to double check.
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { bankTransfer, applyCode } from "@/lib-core/lib-firebase";
import {
  m_isMobile,
  m_whatsappUrl,
} from "../../lib-core/lib-helpers/lib-utils";
import moment from "moment";
import { g_defaultPhone } from "@/lib-core/lib-helpers/lib-constants";

export default {
  name: "cmp-banktransfer",
  components: {},
  props: {
    promo: {
      type: String,
    },
  },
  data() {
    return {
      promocode: "",
      promocodeDiscount: 0,
    };
  },
  computed: {
    ...mapGetters("md_cart", [
      "gt_getProductsInCart",
      "gt_getSessionsInCart",
      "gt_getPackagesInCart",
    ]),
  },
  async mounted() {
    setTimeout(() => {
      this.$store.commit("md_fire/mt_setLoading", false);
    }, 500);
    if (this.promo) this.promocode = this.promo;
  },
  methods: {
    async m_getPromocodePromise() {
      return await applyCode({ promocode: this.promocode });
    },
    async m_confirmsubjets() {
      let l_subjects = this.m_getSubjects();
      let promocodeDiscount = { data: { promoExists: false } };
      if (this.promocode.trim().length > 0) {
        promocodeDiscount = await this.m_getPromocodePromise();
        if (!promocodeDiscount.data.promoExists) {
          this.m_showAlertDialog(
            "Invalid promocode",
            "Please enter a valid promocode or leave it empty"
          );
          return;
        }
      }
      let l_text = this.m_concatSubjects(l_subjects, promocodeDiscount);
      this.m_showDialog(l_text);
    },
    m_basicPrice() {
      return this.gt_getProductsInCart.reduce(
        (p_current, p_next) => p_current + p_next.price * p_next.quantity,
        0
      );
    },
    m_discountedPrice(discount) {
      let price = this.gt_getProductsInCart.reduce(
        (p_current, p_next) => p_current + p_next.price * p_next.quantity,
        0
      );
      return price * ((100 - discount) / 100);
    },
    m_concatSubjects(p_subjects, p_promoObj) {
      let promoObj = p_promoObj.data;
      let l_text = "Checkout items: \n";
      p_subjects.forEach((p_element) => {
        l_text += p_element.subject + "\n";
      });

      l_text += promoObj.promoExists
        ? "\nSubtotal price: " +
          this.m_basicPrice() +
          " AED" +
          "\nPromocode discount: " +
          promoObj.promocodeDiscount +
          "%" +
          "\n\nTotal price: " +
          +this.m_discountedPrice(promoObj.promocodeDiscount) +
          " AED"
        : "\nTotal price: " + this.m_basicPrice() + " AED";
      return l_text;
    },
    m_getSubjects() {
      let l_ret = [];
      this.gt_getProductsInCart.forEach((p_element) => {
        if (p_element.type == "p") {
          l_ret.push({
            subject:
              p_element.quantity +
              " x Package " +
              p_element.nrOfSessions +
              " sessions",
          });
        } else {
          let l_temp = {
            subject: p_element.quantity + " x " + p_element.en,
            // " with tutor " +
            // p_element.tutor.displayName,
          };
          l_ret.push(l_temp);
        }
      });

      return l_ret;
    },
    m_showDialog(p_msg) {
      let l_payload = {
        title: "Confirmation",
        message: p_msg,
        button: {
          yes: "Confirm",
          no: "Cancel",
        },
        callback: async (p_confirm) => {
          if (p_confirm) {
            try {
              const l_payload = {
                products: this.m_checkoutProductsPayload(),
                promocode: this.promocode,
              };
              let bankTransferRes = await bankTransfer(l_payload);
              if (bankTransferRes.data.success) {
                this.promocode = "";
                this.promocodeDiscount = 0;

                await this.$store.commit("md_cart/mt_setCart", []);
                await this.$localforage.setItem("fs_cart", []);
                this.m_showAlertDialog(
                  "",
                  "Your request will be reviewed by the admin"
                );
                this.$router.go(-1);
              } else this.m_showAlertDialog("Error", "Please try again later");
            } catch (p_error) {
              console.error(p_error);
            }
          }
        },
      };

      this.$confirm(l_payload);
    },
    m_showAlertDialog(p_title, p_msg) {
      let l_payload = {
        title: p_title,
        message: p_msg,
        button: {
          yes: "Ok",
        },
      };

      this.$confirm(l_payload);
    },
    m_checkoutProductsPayload() {
      let l_ret = [];
      this.gt_getProductsInCart.forEach((p_element) => {
        if (p_element.type == "p") {
          l_ret.push({
            id: p_element.id,
            type: p_element.type,
            nrOfSessions: p_element.nrOfSessions,
            quantity: p_element.quantity,
          });
        } else {
          let l_temp = {
            id: p_element.id,
            type: p_element.type,
            quantity: p_element.quantity,
            subject: p_element.key,
            tutor: p_element.tutor.email,
          };
          if (p_element.tutor.grade || p_element.tutor.curriculums)
            l_temp.subjectInfo = {};

          if (p_element.tutor.grade)
            l_temp.subjectInfo.grade = p_element.tutor.grade;

          if (p_element.tutor.curriculums)
            l_temp.subjectInfo.curriculums = p_element.tutor.curriculums;

          l_ret.push(l_temp);
        }
      });

      return l_ret;
    },
    m_getNow() {
      const now = moment().toDate();
      return now;
    },
    m_whatsappUrl() {
      return m_whatsappUrl();
    },
    m_number() {
      alert("Support's number is +" + g_defaultPhone);
    },
    m_isMobile() {
      return m_isMobile();
    },
  },
};
</script>

<style>
.btn-small {
  padding: 8px 25px !important;
}
.dashboard:hover {
  text-decoration: underline;
  color: #83bf6f !important;
}
.vc-text {
  white-space: pre-wrap;
  text-align: left;
}
</style>